import clx from 'classnames';
import { FC, SVGAttributes } from 'react';

const LockSVG: FC<SVGAttributes<SVGElement>> = ({ className, ...props }) => {
	return (
		<svg role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 23.849" className={clx('h-[1em] w-[1em]', className)} {...props}>
			<path
				id="lock"
				data-name="Caminho 296"
				d="M19,8.607V7.151a7,7,0,1,0-14,0V8.607A5.5,5.5,0,0,0,2,13.5v5A5.506,5.506,0,0,0,7.5,24h9A5.506,5.506,0,0,0,22,18.5v-5a5.5,5.5,0,0,0-3-4.893ZM12,3.151a4,4,0,0,1,4,4V8H8V7.151a4,4,0,0,1,4-4ZM19,18.5A2.5,2.5,0,0,1,16.5,21h-9A2.5,2.5,0,0,1,5,18.5v-5A2.5,2.5,0,0,1,7.5,11h9A2.5,2.5,0,0,1,19,13.5Z"
				transform="translate(-2 -0.151)"
				fill="currentColor"
			/>
		</svg>
	);
};

export default LockSVG;
