import AddCircleSVG from './svgs/AddCircleSVG';
import CaretSVG from './svgs/CaretSVG';
import CodeSVG from './svgs/CodeSVG';
import DisabilitySVG from './svgs/DisabilitySVG';
import ExcelSVG from './svgs/ExcelSVG';
import FacebookSVG from './svgs/FacebookSVG';
import HandtalkSVG from './svgs/HandtalkSVG';
import LinkedInSVG from './svgs/LinkedInSVG';
import LockSVG from './svgs/LockSVG';
import PowerpointSVG from './svgs/PowerpointSVG';
import TwitterSVG from './svgs/TwitterSVG';
import WhatsappSVG from './svgs/WhatsappSVG';
import WordSVG from './svgs/WordSVG';

export type FlatIcon = {
	className: string;
	type: 'flaticon';
};

export type CustomIcon = {
	Component: React.FC;
	type: 'custom';
};

const iconMap = {
	'add-circle': { Component: AddCircleSVG, type: 'custom' },
	'custom-caret-up': { Component: CaretSVG, type: 'custom' },
	'arrow-left': { className: 'fi fi-br-arrow-left', type: 'flaticon' },
	'arrow-right': { className: 'fi fi-br-arrow-right', type: 'flaticon' },
	book: { className: 'fi fi-br-book-alt', type: 'flaticon' },
	'building-office': { className: 'fi fi-br-building', type: 'flaticon' },
	check: { className: 'fi fi-br-check', type: 'flaticon' },
	'circle-dashed': { className: 'fi fi-br-circle-dashed', type: 'flaticon' },
	close: { className: 'fi fi-br-cross-circle', type: 'flaticon' },
	'close-2': { className: 'fi fi-br-cross-small', type: 'flaticon' },
	code: { Component: CodeSVG, type: 'custom' },
	diamond: { className: 'fi fi-br-diamond', type: 'flaticon' },
	disability: { Component: DisabilitySVG, type: 'custom' },
	download: { className: 'fi fi-br-download', type: 'flaticon' },
	envelope: { className: 'fi fi-br-envelope', type: 'flaticon' },
	excel: { Component: ExcelSVG, type: 'custom' },
	eye: { className: 'fi fi-br-eye', type: 'flaticon' },
	facebook: { Component: FacebookSVG, type: 'custom' },
	filter: { className: 'fi fi-br-filter', type: 'flaticon' },
	handtalk: { Component: HandtalkSVG, type: 'custom' },
	heart: { className: 'fi fi-br-heart', type: 'flaticon' },
	instagram: { className: 'fi fi-brands-instagram', type: 'flaticon' },
	linkedin: { Component: LinkedInSVG, type: 'custom' },
	location: { className: 'fi fi-br-marker', type: 'flaticon' },
	login: { className: 'fi fi-br-user', type: 'flaticon' },
	'left-angle': { className: 'fi fi-br-angle-left', type: 'flaticon' },
	'right-angle': { className: 'fi fi-br-angle-right', type: 'flaticon' },
	'down-angle': { className: 'fi fi-br-angle-down', type: 'flaticon' },
	'up-angle': { className: 'fi fi-br-angle-up', type: 'flaticon' },
	languages: { className: 'fi fi-br-comment-alt', type: 'flaticon' },
	lock: { Component: LockSVG, type: 'custom' },
	'menu-burger': { className: 'fi fi-br-menu-burger', type: 'flaticon' },
	money: { className: 'fi fi-br-money', type: 'flaticon' },
	'new-doc': { className: 'fi fi-br-add-document', type: 'flaticon' },
	package: { className: 'fi fi-br-package', type: 'flaticon' },
	powerpoint: { Component: PowerpointSVG, type: 'custom' },
	portrait: { className: 'fi fi-br-portrait', type: 'flaticon' },
	schooling: { className: 'fi fi-br-graduation-cap', type: 'flaticon' },
	search: { className: 'fi fi-br-search', type: 'flaticon' },
	twitter: { Component: TwitterSVG, type: 'custom' },
	turnon: { className: 'fi fi-br-power', type: 'flaticon' },
	whatsapp: { Component: WhatsappSVG, type: 'custom' },
	word: { Component: WordSVG, type: 'custom' },
	clock: { className: 'fi fi-br-clock', type: 'flaticon' },
};

export type IconNames = keyof typeof iconMap;

export default iconMap as Record<keyof typeof iconMap, FlatIcon | CustomIcon>;
