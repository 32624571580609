import clx from 'classnames';
import { FC, SVGAttributes } from 'react';

const CaretSVG: FC<SVGAttributes<SVGElement>> = ({ className, ...props }) => {
	return (
		<svg role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 28" className={clx('h-[1em] w-[1em]', className)} {...props}>
			<path id="arrow" data-name="Polígono 2" d="M13.4,4.558a3,3,0,0,1,5.209,0l10.83,18.953A3,3,0,0,1,26.83,28H5.17a3,3,0,0,1-2.6-4.488Z" />
		</svg>
	);
};

export default CaretSVG;
