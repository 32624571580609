import clx from 'classnames';
import { FC, SVGAttributes } from 'react';

const ExcelSVG: FC<SVGAttributes<SVGElement>> = ({ className, ...props }) => {
	return (
		<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.5 50.296" className={clx('h-[1em] w-[1em]', className)} {...props}>
			<g id="icons8-microsoft-excel" transform="translate(-6 -6)">
				<path
					id="Caminho_7"
					data-name="Caminho 7"
					d="M47.354,10H25V49.119H47.354a1.4,1.4,0,0,0,1.4-1.4V11.4A1.4,1.4,0,0,0,47.354,10Z"
					transform="translate(-0.251 1.588)"
					fill="#4caf50"
				/>
				<path
					id="Caminho_8"
					data-name="Caminho 8"
					d="M34.78,15h9.78v4.191H34.78Zm0,13.971h9.78v4.191H34.78Zm0,6.986h9.78v4.191H34.78Zm0-13.971h9.78v4.191H34.78ZM25,15h6.986v4.191H25Zm0,13.971h6.986v4.191H25Zm0,6.986h6.986v4.191H25Zm0-13.971h6.986v4.191H25Z"
					transform="translate(-0.251 3.574)"
					fill="#fff"
				/>
				<path id="Caminho_9" data-name="Caminho 9" d="M35.339,56.3,6,50.708V11.588L35.339,6Z" transform="translate(0 0)" fill="#2e7d32" />
				<path
					id="Caminho_10"
					data-name="Caminho 10"
					d="M22.8,36.56l-3.368-6.372a5.609,5.609,0,0,1-.4-1.31h-.052a10.06,10.06,0,0,1-.453,1.368L15.144,36.56H9.9l6.234-9.781L10.425,17h5.361l2.8,5.862a10.193,10.193,0,0,1,.587,1.647h.056c.109-.379.313-.95.613-1.7L22.947,17h4.911l-5.866,9.695,6.03,9.862H22.8Z"
					transform="translate(1.547 4.368)"
					fill="#fff"
				/>
			</g>
		</svg>
	);
};

export default ExcelSVG;
