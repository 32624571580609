import clx from 'classnames';
import { FC, HTMLAttributes, useMemo } from 'react';
import deprecationWarning from '../Utils/deprecationWarning';
import iconMap, { CustomIcon, FlatIcon } from './iconMap';

type Props = {
	className?: string;
	// deprecated
	icon?: string;
	name?: keyof typeof iconMap;
} & HTMLAttributes<HTMLElement>;

const Icon: FC<Props> = ({ className, icon, name, ...props }) => {
	const iconMapped: CustomIcon | FlatIcon | undefined = useMemo(() => {
		if (icon) {
			deprecationWarning('Icon', 'prop "icon" is deprecated and will be removed soon, use "name" instead.');

			if (icon.startsWith('fi '))
				return {
					className: icon,
					type: 'flaticon',
				};

			if (iconMap[icon as keyof typeof iconMap]) return iconMap[icon as keyof typeof iconMap];
		}

		if (name) return iconMap[name];
	}, [icon, name]);

	if (!iconMapped && !className?.includes('fi ')) {
		console.info(`Icon: please provide a valid icon name.`);
	}

	if (className?.includes('fi ')) {
		deprecationWarning('Icon', 'using the "className" prop for choosing the icon is deprecated and will be removed soon, use "name" instead.');
	}

	if (iconMapped && iconMapped.type === 'custom') {
		const { Component } = iconMapped;

		return (
			<i className={clx('flex items-center justify-center aspect-square', className)} {...props}>
				<Component />
			</i>
		);
	}

	return <i className={clx((iconMapped as FlatIcon)?.className, 'flex items-center justify-center aspect-square', className)} {...props}></i>;
};

export default Icon;
