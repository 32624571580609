import clx from 'classnames';
import { FC, SVGAttributes } from 'react';

const PowerpointSVG: FC<SVGAttributes<SVGElement>> = ({ className, ...props }) => {
	return (
		<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className={clx('h-[1em] w-[1em]', className)} {...props}>
			<g id="icons8-microsoft-powerpoint" transform="translate(-6 -6)">
				<path
					id="Caminho_11"
					data-name="Caminho 11"
					d="M47.194,10H25V48.9H47.194a1.387,1.387,0,0,0,1.387-1.389V11.389A1.387,1.387,0,0,0,47.194,10Z"
					transform="translate(7.419 1.548)"
					fill="#ff8a65"
				/>
				<path
					id="Caminho_12"
					data-name="Caminho 12"
					d="M24,34.42H43.42v2.774H24Zm0,5.548H43.42v2.774H24ZM32.323,15a8.323,8.323,0,1,0,8.323,8.323H32.323Z"
					transform="translate(7.032 3.52)"
					fill="#fbe9e7"
				/>
				<path id="Caminho_13" data-name="Caminho 13" d="M32,13v8.323h8.323A8.324,8.324,0,0,0,32,13Z" transform="translate(10.129 2.725)" fill="#fbe9e7" />
				<path id="Caminho_14" data-name="Caminho 14" d="M35.129,56,6,50.444V11.556L35.129,6Z" transform="translate(0 0)" fill="#e64a19" />
				<path
					id="Caminho_15"
					data-name="Caminho 15"
					d="M18.7,17H12V36.42h4.161V29.73h2.153a8.039,8.039,0,0,0,5.5-1.809,5.994,5.994,0,0,0,2.056-4.733Q25.872,17,18.7,17Zm-.741,9.412H16.161V20.358h1.795q3.414,0,3.414,2.993Q21.37,26.414,17.956,26.412Z"
					transform="translate(2.337 4.29)"
					fill="#fff"
				/>
			</g>
		</svg>
	);
};

export default PowerpointSVG;
