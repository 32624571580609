import clx from 'classnames';
import { FC, HTMLAttributes } from 'react';

const WordSVG: FC<HTMLAttributes<HTMLOrSVGElement>> = ({ className, ...props }) => {
	return (
		<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 51.01" className={clx('h-[1em] w-[1em]', className)} {...props}>
			<path
				id="icons8-microsoft-word"
				d="M29.4.032.829,5.453A1.038,1.038,0,0,0,0,6.473V44.547a1.038,1.038,0,0,0,.829,1.02L29.4,50.988a1.067,1.067,0,0,0,.191.032.981.981,0,0,0,.638-.223,1.057,1.057,0,0,0,.383-.8V1.02a1.056,1.056,0,0,0-.383-.8A.98.98,0,0,0,29.4.032Zm3.253,6.091v7.143H44.9v2.041H32.653v5.1H44.9v2.041H32.653v5.1H44.9v2.041H32.653v6.122H44.9v2.041H32.653V44.9H47.959A2.045,2.045,0,0,0,50,42.857V8.163a2.045,2.045,0,0,0-2.041-2.041ZM4.719,15.976H8.355l2.073,12.691a19.068,19.068,0,0,1,.191,2.2h.064A13.648,13.648,0,0,1,11,28.635L13.68,15.976h3.571l2.423,12.819c.088.47.155,1.144.223,2.041h.032a17.185,17.185,0,0,1,.223-2.137l2.041-12.723h3.38L21.652,35.044h-3.7L15.5,22.831a13.342,13.342,0,0,1-.223-2.168h-.032a17.255,17.255,0,0,1-.287,2.168L12.436,35.044H8.61Z"
				transform="translate(0 -0.011)"
				fill="#0061fd"
			/>
		</svg>
	);
};

export default WordSVG;
