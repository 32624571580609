import clx from 'classnames';
import { FC, SVGAttributes } from 'react';

const AddCircleSVG: FC<SVGAttributes<SVGElement>> = ({ className, ...props }) => {
	return (
		<svg role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" className={clx('h-[1em] w-[1em]', className)} {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM14.4695 3.82751C14.0797 3.43774 13.5512 3.21853 13 3.218C12.4488 3.21853 11.9203 3.43774 11.5305 3.82751C11.1407 4.21728 10.9215 4.74578 10.921 5.297V10.769H5.44901C4.89761 10.769 4.36882 10.988 3.97893 11.3779C3.58904 11.7678 3.37 12.2966 3.37 12.848C3.37 13.3994 3.58904 13.9282 3.97893 14.3181C4.36882 14.708 4.89761 14.927 5.44901 14.927H10.921V20.4C10.921 20.9514 11.14 21.4802 11.5299 21.8701C11.9198 22.26 12.4486 22.479 13 22.479C13.5514 22.479 14.0802 22.26 14.4701 21.8701C14.86 21.4802 15.079 20.9514 15.079 20.4V14.927H20.552C20.825 14.927 21.0954 14.8732 21.3476 14.7687C21.5998 14.6643 21.829 14.5111 22.0221 14.3181C22.2151 14.125 22.3683 13.8958 22.4727 13.6436C22.5772 13.3914 22.631 13.121 22.631 12.848C22.631 12.575 22.5772 12.3046 22.4727 12.0524C22.3683 11.8002 22.2151 11.571 22.0221 11.3779C21.829 11.1849 21.5998 11.0317 21.3476 10.9273C21.0954 10.8228 20.825 10.769 20.552 10.769H15.079V5.297C15.0785 4.74578 14.8593 4.21728 14.4695 3.82751Z"
			/>
		</svg>
	);
};

export default AddCircleSVG;
