import clx from 'classnames';
import { FC, SVGAttributes } from 'react';

const CodeSVG: FC<SVGAttributes<SVGElement>> = ({ className, ...props }) => {
	return (
		<svg role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.066 47.187" className={clx('h-[1em] w-[1em]', className)} {...props}>
			<path
				id="Code"
				data-name="Code"
				d="M24.887,47.384l-6.729-1.93a1,1,0,0,1-.685-1.237L29.876.961A1,1,0,0,1,31.113.275l6.728,1.93a1,1,0,0,1,.686,1.237L26.123,46.7a1,1,0,0,1-1.237.685ZM13.02,35.868.293,24.054a.885.885,0,0,1-.277-.82.886.886,0,0,1,.277-.82L13.02,10.6a1.055,1.055,0,0,1,1.414,0l3.536,3.281a.884.884,0,0,1,0,1.313L9.309,23.234l8.662,8.04a.883.883,0,0,1,0,1.312l-3.536,3.281a1.055,1.055,0,0,1-1.414,0Zm29.611,0L39.1,32.586a.883.883,0,0,1,0-1.312l8.663-8.04L39.1,15.2a.884.884,0,0,1,0-1.313L42.631,10.6a1.055,1.055,0,0,1,1.414,0L56.773,22.415a.884.884,0,0,1,.277.82.883.883,0,0,1-.277.82L44.045,35.868a1.057,1.057,0,0,1-1.414,0Z"
			/>
		</svg>
	);
};

export default CodeSVG;
